////////////////////////////////////////////////////////////////
// Bootstrap 4
////////////////////////////////////////////////////////////////
@import url("inc/bootstrap/bootstrap.min.css");

////////////////////////////////////////////////////////////////
// Plugins
////////////////////////////////////////////////////////////////
// Owl Carousel
@import url("inc/owl-carousel/owl.carousel.min.css");
@import url("inc/owl-carousel/owl.theme.default.css");

////////////////////////////////////////////////////////////////
// Theme Settings
////////////////////////////////////////////////////////////////
@import "theme-settings";

////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////
// Body - General Settings
@import "body";
// Header
@import "section/header";
// Bottom Menu
@import "section/bottomMenu";
// Content
@import "section/content";
// Sidebar
@import "section/sidebar";
// Footer
@import "section/footer";

////////////////////////////////////////////////////////////////
// UI Kit Framework
////////////////////////////////////////////////////////////////
@import "ui-framework";

////////////////////////////////////////////////////////////////
// Dark Mode
////////////////////////////////////////////////////////////////
@import "darkmode";

app-root {
  display: block;
  height: 100%;
  overflow-y: scroll;
}

.crew-modalbox {
  width: 100% !important;
  max-width: 800px !important;
  height: 100%;
  max-height: 1000px;
  // z-index: 999;
}

.cdk-overlay-container {
  z-index: 9999;
}

.alert-button-role-confirm {
  span {
    color: red !important;
    font-weight: 500;
    font-size: 13px;
  }
}

.alert-button-role-cancel {
  span {
    color: grey !important;
    font-weight: 500;
    font-size: 13px;
  }
}

.alert-button-role-ok {
  span {
    color: #ff0044 !important;
    font-weight: 400;
    font-size: 13px;
  }
}

.test {
  // margin-top: -1rem !important;
  border-top: 0.55px solid rgba(0, 0, 0, 0.2);
}

.appHeader.scrolled.bg-primary.is-active {
  background: #ff0044 !important;
  // z-index: 99999;
}

.fit-content {
  max-width: 800px;
  margin: auto;
}

.fit-content2 {
  max-width: 1000px;
  margin: auto;
}

.fit-content-400 {
  max-width: 400px;
  margin: auto;
}

.disabled-env {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: center;
  align-self: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  // font-weight: bold;
  z-index: 990;
  border-radius: 15px;
  padding: 20px;
  font-size: 25px;
  text-align: center;
}

.appFooter {
  border-top: 1px solid $colorLine;
  padding: 24px 16px;
  font-size: 12px;
  text-align: center;
  line-height: 1.2em;
  background: #fff;
  .footer-title {
    font-weight: $medium;
    color: $colorHeading;
    margin-bottom: 8px;
  }
  .btn {
    margin: 0 5px;
  }
}
.fixed-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  min-height: 84px;
  right: 0;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: $safeBottom;
  > div {
    width: 100%;
  }
  &.transparent {
    background: transparent !important;
  }
}
.footer-logo{
  height: 16px;
}
.footer-link{
  padding: 0;
  border-top: 1px solid rgba($colorLine, .5);
  border-bottom: 1px solid rgba($colorLine, .5);
  margin-left: -16px;
  margin-right: -16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a{
    float: 1;
    padding: 12px 2px;
    width: 100%;
    color: $colorText;
    display: block;
    span{
      display: block;
    }
    i.icon,
    ion-icon{
      font-size: 24px;
      margin-bottom: 4px;
    }
  }
}
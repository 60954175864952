.appBottomMenu {
  min-height: 56px;
  position: fixed;
  z-index: 999;
  bottom: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid $colorLine;
  padding-left: 4px;
  padding-right: 4px;
  padding-bottom: $safeBottom;
  &.no-border{
    border: 0 !important;
    box-shadow: 0 !important;
  }
  &.rounded{
    border-radius: 24px 24px 0 0 !important;
    .item{
      &:before{
        display: none;
      }
    }
  }
  .item {
    font-size: 9px;
    letter-spacing: 0;
    text-align: center;
    width: 100%;
    height: 56px;
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &:before{
      content: '';
      display: block;
      height: 2px;
      border-radius: 0 0 10px 10px;
      background: transparent;
      position: absolute;
      left: 4px;
      right: 4px;
      top: 0;
    }
    .col{
      width: 100%;
      padding: 0 4px;
      text-align: center;
    }
    i.icon,
    ion-icon{
      display: inline-flex;
      margin: 1px auto 3px auto;
      font-size: 26px;
      line-height: 1em;
      color: $colorHeading;
      transition: 0.1s all;
      display: block;
      margin-top: 1px;
      margin-bottom: 3px;
    }
    .action-button{
      display: inline-flex;
      width: 50px;
      height: 50px;
      margin-left: -5px;
      margin-right: -5px;
      align-items: center;
      justify-content: center;
      border-radius: 200px;
      background: $colorPrimary;
      &.large{
        width: 60px;
        height: 60px;
        margin-top: -20px;
        margin-left: -10px;
        margin-right: -10px;
      }
      i.icon,
      ion-icon{
        color: #FFF !important;
        margin: 0 !important;
        line-height: 0 !important;
      }
    }
    strong {
      margin-top: 4px;
      display: block;
      color: $colorHeading;
      font-weight: $regular;
      transition: 0.1s all;
    }
    &:active{
      opacity: .8;
    }
    &.active {
      &:before{
        background: transparent;
      }
      i.icon,
      ion-icon,
      strong {
        color: $colorPrimary !important;
        font-weight: $medium;
      }
    }
    &:hover {
      i.icon,
      ion-icon,
      strong {
        color: $colorHeading;
      }
    }
  }
  &.text-light{
    color: #FFF;
    .item{
      color: #FFF;
      opacity: .7;
      i.icon,
      ion-icon,
      strong{
        color:#FFF;
      }
      &.active{
        opacity: 1;
        i.icon,
        ion-icon,
        strong{
          color: #FFF !important;
        }
      }
    }
  }
  &.bg-primary,
  &.bg-secondary,
  &.bg-success,
  &.bg-warning,
  &.bg-danger,
  &.bg-info,
  &.bg-light,
  &.bg-dark{
    border: 0;
    .item{
      &:before{
        display: none;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $colorHeading;
  margin: 0 0 10px 0;
  letter-spacing: -0.02em;
  line-height: 1.3em;
  small {
    color: rgba($colorHeading, 0.6);
  }
  .badge {
    line-height: 1em;
  }
}
.text-muted {
  font-size: $fontSizeSub;
  color: $colorText !important;
}
h1 {
  font-size: $fontSizeHeadingXLarge;
  font-weight: $bold;
}
h2 {
  font-size: $fontSizeHeadingLarge;
  font-weight: $bold;
}
h3 {
  font-size: $fontSizeHeading;
  font-weight: $bold;
}
h4 {
  font-size: $fontSize;
  font-weight: $medium;
}
h5 {
  font-size: $fontSizeSub;
  font-weight: $medium;
}
h6 {
  font-size: $fontSizeCaption;
  font-weight: $medium;
}
.lead {
  font-weight: $regular;
  font-size: $fontSizeHeading;
  color: $colorText !important;
}
mark {
  border-radius: $borderRadius;
}
.text-large {
  font-size: $fontSizeHeadingXLarge;
}

strong,
b {
  font-weight: $medium;
}

.page-title {
  &.inset {
    padding-left: 16px;
    padding-right: 16px;
  }
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  .in {
    padding-right: 16px;
  }
  .link {
    font-size: $fontSizeSub;
    font-weight: $medium;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3px;
    &:hover,
    &:active {
      opacity: 0.8;
    }
  }
  strong {
    font-weight: $medium;
    color: $colorLight;
    font-size: $fontSizeSub;
    text-transform: uppercase;
    line-height: 1.2em;
  }
  .title {
    margin: 0;
    font-size: $fontSizeHeadingLarge;
    line-height: 1.2em;
  }
}
.section.full {
  .page-title {
    padding-left: 16px;
    padding-right: 16px;
  }
}

code{
  color: $colorPrimary;
}
figure.codebox{
  background: $colorLine;
  border-radius: $borderRadius;
  padding: 5px 12px;
  code{
      color: $colorHeading;
      line-height: 1.2em;
  }
}
.button.goTop{
    width: 46px;
    height: 46px;
    display: none;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: $colorSecondary;
    color: #FFF;
    position: fixed;
    z-index: 99;
    right: 16px;
    bottom: 70px;
    font-size: 18px;
    margin-bottom: $safeBottom;
    box-shadow: $boxShadow;
    &:hover,
    &:active{
        background: darken($colorSecondary, 5%);
    }
    &.show{
        display: flex;
    }
}